import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker from '../common/DatePicker/DatePicker';
import GEVTexttarea from './GEVTexttarea';
import { OperationProps } from '../../types/gev/BusinessOperationFormProps';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { BusinessOperationsKeys } from '../../pages/BusinessOperations/BusinessOperations';
import { NoFormSelectedClass } from '../../types/gev/BusinessOperationsData';
import { FormError, useFormErrorContext } from '../../utils/context/FormErrorContext';
import ErrorMessages from '../common/ErrorMessages/ErrorMessages';

function isNoFormSelected(dataset: NoFormSelectedClass) {
    return (
        dataset &&
        typeof dataset.selected === 'boolean' &&
        'dateOfChange' in dataset
    );
}

export default function NoFormSelected(props: OperationProps): JSX.Element {
    const { t } = useTranslation('gev');
    const { operation, incrementErrCount } = props
    const { formData, setFormData } = useBusinessOperationsState();
    const [errorFields, setErrorFields] = useState({});
    // useEffect(() => {
    //     if (props.validating) {
    //         const err: Record<string, string> = {}
    //         if (!data["changesToProperty"]) {
    //             err["changesToProperty"] = t("missingRequiredField")
    //         }
    //         if (data["changesToProperty"] && data["changesToProperty"].toString().length > 200) {
    //             err["changesToProperty"] = t("max-200-chars")
    //         }
    //         if (!data["dateOfChange"]) {
    //             err["dateOfChange"] = t("missingRequiredField")
    //         }
    //         props.validate(operation)
    //         props.setData(operation, data)
    //         if (Object.keys(err).length) {
    //             props.setErrors(operation, err)
    //         }
    //     }
    // }, [data, props, t])
    const handleDateChange = (date: Date | null | undefined) => {
        const parsedDate = date ? date.toISOString().substring(0, 10) : null;

        setFormData((prevData) => {
            const dataset = prevData[operation as BusinessOperationsKeys] as NoFormSelectedClass;
            if (isNoFormSelected(dataset)) {
                dataset.dateOfChange = parsedDate
            }

            return {
                ...prevData,
                [operation]: { ...dataset }
            }
        });
    }

    const currentValue = isNoFormSelected(formData[operation as BusinessOperationsKeys]) ? (formData[operation as BusinessOperationsKeys] as NoFormSelectedClass).dateOfChange : null;

    const { errors } = useFormErrorContext();
    const [formErrors, setFormErrors] = useState<FormError[]>([]);
    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((error) => {
                if (error.primaryKey && error.primaryKey.split('.')[1] === operation) {
                    const splitKey = error.primaryKey.split('.');
                    splitKey.shift();
                    error.primaryKey = splitKey.join(".");
                    const localKey = error.primaryKey//.split('.').pop() as string;
                    setErrorFields(prevData => ({ ...prevData, [localKey]: error.message }));
                    setFormErrors(prevData => [...prevData, error])
                    incrementErrCount(operation as BusinessOperationsKeys)
                }
            })
        }
        else {
            setFormErrors([]);
            setErrorFields({});
        }
    }, [errors, operation, incrementErrCount]);
    return (
        <div>
            <ErrorMessages errors={formErrors} />
            <form>
                <table width={'100%'} role="none" className='gev-table'>
                    <tbody>
                        <GEVTexttarea
                            index={1}
                            operation={operation}
                            name="changesToProperty"
                            errors={props.errors}
                            hasErrors={(operation + ".changesToProperty") in errorFields}
                        />
                        <tr className='gev-input-tr'>
                            <td>
                                2.
                            </td>
                            <td>
                                <div className='gev-input-label'>
                                    {t(operation + ".dateOfChange")}
                                </div>
                                <div className='gev-div-dateOfChange'>
                                    <DatePicker id={operation + ".dateOfChange"} name={operation + ".dateOfChange"}
                                        date={currentValue}
                                        position="right-end" className="gev-dateOfChange"
                                        title={t(operation + ".dateOfChange")}
                                        hasError={(operation + ".dateOfChange") in errorFields}
                                        errorMessage={props.errors && props.errors["dateOfChange"] as string}
                                        onChange={handleDateChange} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )
}
