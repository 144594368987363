import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { ControlledAccordion, useAccordionProvider } from '@szhsin/react-accordion';
import { getAssessmentInfo, putAssessmentInfo, resetGetAssessmentInfo } from '../../redux/actions/assessmentInfo/assessmentInfo';
import { RootState } from '../../redux/reducers/root';
import { SaveDataState } from '../../redux/reducers/getSaveData/saveDataReducer';
import { SaveResultState } from '../../redux/reducers/getSaveData/saveResultReducer';
import AccordionItem, { DefaultAccordionProviderOptions } from '../../components/common/AccordionItem/AccordionItem';
import Overview from '../../components/gev/Overview';
import CommercialGrainElevatorFacility from '../../components/gev/CommercialGrainElevatorFacility';
import FeedMillingProcessingFacility from '../../components/gev/FeedMillingProcessingFacility';
import OtherAgriBusiness from '../../components/gev/OtherAgriBusiness';
import FarmingOperationCommercialGrain from '../../components/gev/FarmingOperationCommercialGrain';
import NoFormSelected from '../../components/gev/NoFormSelected';
import { AssessmentInfoState } from '../../types/gev/AssessmentInfoState';
import { BusinessOperationsData } from '../../types/gev/BusinessOperationsData';
import { OperationProps } from '../../types/gev/BusinessOperationFormProps';
import { useBusinessOperationsState } from '../../utils/context/BusinessOperationsContext';
import { useFormErrorContext } from '../../utils/context/FormErrorContext';
import "./BusinessOperations.css";

export type BusinessOperationsKeys = keyof BusinessOperationsData;

export const farmingOption = 'farmingOperationCommercialGrain'
export const noFormSelectedOption = 'noFormSelected'
export const allOtherOptions = [
    'commercialGrainElevatorFacility',
    'feedMillingProcessingFacility',
    'otherAgriBusiness'
]
export const orderedBusinessOperations = [farmingOption, ...allOtherOptions, noFormSelectedOption]

export default function BusinessOperations(): JSX.Element {
    const { t } = useTranslation('gev')
    const dispatch = useDispatch()
    const providerValue = useAccordionProvider(DefaultAccordionProviderOptions)

    const getAssessmentInfoState: AssessmentInfoState = useSelector((state: RootState) => state.assessmentInfoReducer.getAssessmentInfo)
    const saveData: SaveDataState = useSelector((state: RootState) => state.saveDataReducer.saveData)
    const saveResultState: SaveResultState = useSelector((state: RootState) => state.saveDataReducer.saveResult);
    const { clearErrors } = useFormErrorContext();
    const { formData, setFormData } = useBusinessOperationsState();
    const [errorGrp, setErrorGrp] = useState<Record<BusinessOperationsKeys, number>>({
        commercialGrainElevatorFacility: 0,
        farmingOperationCommercialGrain: 0,
        feedMillingProcessingFacility: 0,
        otherAgriBusiness: 0,
        noFormSelected: 0
    })
    // const [validating, setValidating] = useState(false)
    // const [validation, setValidation] = useState<string[]>([])
    const currentSnapshotId = localStorage.getItem("currentSnapshotId") as string
    const [isUpdateReqSent, setIsUpdateReqSent] = useState(false);

    useEffect(() => {
        if (!getAssessmentInfoState.loading && !getAssessmentInfoState.success) {
            dispatch(getAssessmentInfo(currentSnapshotId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!getAssessmentInfoState.loading) {
            if (getAssessmentInfoState.success) {
                setFormData(getAssessmentInfoState.formData)
            } else {
                if (getAssessmentInfoState.message) {
                    toast.error(getAssessmentInfoState.message)
                }
            }
            dispatch(resetGetAssessmentInfo());
        }
    }, [getAssessmentInfoState, dispatch, setFormData, saveResultState.status]);

    useEffect(() => {
        if (isUpdateReqSent && !saveResultState.loading) {
            if (saveResultState.status === 200) {
                dispatch(getAssessmentInfo(currentSnapshotId));
            }
            else {
                // toast.error('Failed to save data. Please fix errors and try again');
            }
            setIsUpdateReqSent(false);
        }
    }, [dispatch, setFormData, currentSnapshotId, saveResultState, isUpdateReqSent]);
    // const validate = (operationKey: string) => {
    //     if (!validation.includes(operationKey)) {
    //         setValidation([...validation, operationKey])
    //     }
    // }

    useEffect(() => {
        // Trigger save only if context changed
        if (saveData.saving && (formData !== getAssessmentInfoState.formData)) {
            clearErrors();
            setErrorGrp({
                commercialGrainElevatorFacility: 0,
                farmingOperationCommercialGrain: 0,
                feedMillingProcessingFacility: 0,
                otherAgriBusiness: 0,
                noFormSelected: 0
            })
            dispatch(putAssessmentInfo(currentSnapshotId, formData));
            setIsUpdateReqSent(true);
        }
    }, [dispatch, saveData.saving, currentSnapshotId, formData, getAssessmentInfoState.formData, clearErrors]);


    const displayOperation = (operationKey: BusinessOperationsKeys) => {
        const props: OperationProps = {
            operation: operationKey,
            incrementErrCount: incrementErrCount,
            // validating: validating,
            // validate: validate,
            errors: {},
            // setErrors: setOperationErrors,
        }
        switch (operationKey) {
            case "commercialGrainElevatorFacility":
                return <CommercialGrainElevatorFacility {...props} />
            case "feedMillingProcessingFacility":
                return <FeedMillingProcessingFacility {...props} />
            case "otherAgriBusiness":
                return <OtherAgriBusiness {...props} />
            case "farmingOperationCommercialGrain":
                return <FarmingOperationCommercialGrain {...props} />
            case "noFormSelected":
                return <NoFormSelected {...props} />
            default:
                return null
        }
    }

    const incrementErrCount = (operationKey: BusinessOperationsKeys) => {
        const newCount = errorGrp[operationKey] + 1;
        setErrorGrp(prevData => ({
            ...prevData,
            [operationKey]: newCount
        }));
    }

    // useEffect(() => {
    //     if (validating) {
    //         const operationsNeedValidation = ["overview"]
    //         Object.keys(assessmentInformation).forEach((key) => {
    //             if (assessmentInformation[key]["selected"]) {
    //                 operationsNeedValidation.push(key)
    //             }
    //         })
    //         const doneValidation = operationsNeedValidation.every(item => validation.includes(item))
    //         if (doneValidation) {
    //             setValidating(false)
    //             const errorKeys = Object.keys(errors)
    //             let valid = errorKeys.length === 0
    //             if (localStorage.getItem("isInternalUser")) {
    //                 valid = true
    //                 setErrors({})
    //             }

    //             if (valid) {
    //                 Object.keys(assessmentInformation).forEach((key) => {
    //                     if (assessmentInformation[key]["selected"]) {
    //                         assessmentInformation[key] = saveInformation[key]
    //                     }
    //                 })

    //                 dispatch(resetPutAssessmentInfo())
    //                 dispatch(putAssessmentInfo(currentSnapshotId,
    //                     { snapshotId: Number(currentSnapshotId), assessmentInfo: assessmentInformation }))
    //                 setPuttingData(true)
    //             } else {
    //                 Object.keys(assessmentInformation).forEach((key) => {
    //                     if (assessmentInformation[key]["selected"]) {
    //                         toggle(key, errorKeys.includes(key))
    //                     }
    //                 })
    //                 document.getElementById("bottom-of-overview")?.scrollIntoView({ behavior: 'smooth' })
    //                 dispatch(resetSaving());
    //                 dispatch(setNextActiveMenuItem(initialMenuItem));
    //             }
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [dispatch, errors, validating, validation])

    return (
        <div className='partial-width'>
            <Overview />
            <div className='form-container'>
                <ControlledAccordion providerValue={providerValue}>
                    {
                        Object.keys(formData).length > 0
                        && Object.keys(formData).map((key) => {
                            return (
                                (formData[key as BusinessOperationsKeys].selected) &&
                                <AccordionItem header={<>
                                    {t('overview.' + key)}
                                    {errorGrp[key as BusinessOperationsKeys] > 0 &&
                                        <FontAwesomeIcon className='accordion-err-count' icon={faExclamationCircle} />}
                                </>}
                                    key={key}
                                    itemKey={key}
                                >
                                    {displayOperation(key as BusinessOperationsKeys)}
                                </AccordionItem>
                            )
                        })
                    }
                </ControlledAccordion>
            </div>
        </div>

    )
}
