import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { getTestRolls, validateWac } from '../../redux/actions/campaign/landing';
import { getGlobalSetting } from '../../redux/actions/global/global';
import { getProfileOverview } from '../../redux/actions/profile/profile';
import { resetSaveData, resetSaveResult } from '../../redux/actions/saveData/saveData';
import { EXTERNAL_IN_PROGRESS, EXTERNAL_SUBMITTED, INTERNAL_IN_PROGRESS, NEW_CREATION, RESET_GET_PROFILE_OVERVIEW, RESET_LOOKUPS, RESET_PROPERTIES } from '../../redux/actionTypes/actionsTypes';
import { RootState } from '../../redux/reducers/root';
import { GlobalSettingState } from '../../redux/reducers/getGlobalSetting/getGlobalSettingReducer';
import { ProfileOverviewState } from '../../redux/reducers/getProfile/getProfileOverviewReducer';
import { PropertiesState } from '../../redux/reducers/getProperties/propertiesReducer';
import Button from '../../components/common/Button/Button';
import Input from '../../components/common/Input/Input';
import ErrorMessages from '../../components/common/ErrorMessages/ErrorMessages';
import LoadingSpinner from '../../components/common/LoadingSpinner/LoadingSpinner';
import Pagination from '../../components/common/Pagination/Pagination';
import Search from '../../components/common/Search/Search';
import LandingBanner from '../../components/LandingBanner/LandingBanner';
import Footer from '../../layouts/Footer/Footer';
import Header from '../../layouts/Header/Header';
import PropertyObject from '../../types/property/PropertyObject';
import { FormError, useFormErrorContext } from '../../utils/context/FormErrorContext';
import "./Landing.css";

export default function Landing(): JSX.Element {
    const isValidateWacEnabled = process.env.REACT_APP_IS_VALIDATE_WAC_ENABLED === "1" ? true : false
    console.log("isValidateWacEnabled", isValidateWacEnabled)
    const { t } = useTranslation('landing');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const profileOverview: ProfileOverviewState = useSelector((state: RootState) => state.profileReducer.getProfileOverview);
    const propertiesState: PropertiesState = useSelector((state: RootState) => state.propertiesReducer.properties);
    const globalSettingState: GlobalSettingState = useSelector((state: RootState) => state.globalSettingReducer.globalSetting);

    const [isProfileReqSent, setIsProfileReqSent] = useState(false);
    const [currentProperty, setCurrentProperty] = useState<PropertyObject>();
    const [campaignYear, setCampaignYear] = useState<number | null>(null);
    const [showValidateWacDialog, setShowValidateWacDialog] = useState<boolean>(isValidateWacEnabled)
    const { addError, clearErrors, errors } = useFormErrorContext()
    const [formErrors, setFormErrors] = useState<FormError[]>([])
    const gettingProperties = useRef(false)

    useEffect(() => {
        if (!globalSettingState.loading && !globalSettingState.success) {
            dispatch(getGlobalSetting());
        }
    }, [dispatch, globalSettingState.loading, globalSettingState.success]);

    useEffect(() => {
        if (globalSettingState.success && campaignYear === null) {
            setCampaignYear(globalSettingState.setting["campaign_year"] as number);
        }
    }, [campaignYear, globalSettingState.setting, globalSettingState.success])

    // Pagination related states and methods
    const [pageData, setPageData] = useState<Array<PropertyObject> | null>(propertiesState.rolls);
    const [totalPages, setTotalPages] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(7);

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        setPageData(propertiesState.rolls.slice(itemOffset, endOffset));
        setTotalPages(Math.ceil(propertiesState.rolls.length / itemsPerPage));
    }, [itemOffset, itemsPerPage, propertiesState.rolls]);

    useEffect(() => {
        if (isProfileReqSent && !profileOverview.loading && profileOverview.found && campaignYear) {
            setIsProfileReqSent(false);
            if (!profileOverview.profile.current_campaign) {
                toast.error("No campaign found for roll");
            }
            else {
                let path = `/${campaignYear}/${currentProperty?.rollNumber}/${currentProperty?.profileId}/${currentProperty?.profileTypeCode}/`;
                switch (currentProperty?.statusCode as string) {
                    case NEW_CREATION:
                        path += "start";
                        break;
                    case EXTERNAL_IN_PROGRESS:
                        path += "continue";
                        break;
                    case EXTERNAL_SUBMITTED:
                        path += "overview";
                        break;
                    default:
                        path += "overview";
                }
                navigate(path);
                localStorage.setItem('rollNumber', currentProperty?.rollNumber as string);
                localStorage.setItem('profileId', currentProperty?.profileId as string);
                localStorage.setItem('propertyCode', currentProperty?.propertyCode as string);
                dispatch(resetSaveData());
                dispatch(resetSaveResult());
            }
        }
        else if (isProfileReqSent && !profileOverview.loading && !profileOverview.found) {
            setIsProfileReqSent(false);
            toast.error("Failed to retrieve profile details");
        }
    }, [campaignYear, currentProperty, dispatch, isProfileReqSent, navigate, profileOverview])

    const chooseProperty = (property: PropertyObject) => {
        setCurrentProperty(property);
        if (profileOverview && profileOverview.profile.profile_type_code !== property.profileTypeCode) {
            dispatch({ type: RESET_LOOKUPS });
        }
        if (property.profileId === "0") {
            toast.error("Invalid profile, please verify details");
        } else {
            dispatch(getProfileOverview(property.profileId));
            setIsProfileReqSent(true);
        }
    }

    const handleItemsPerPage = (itemCount: number) => {
        setItemsPerPage(itemCount);
    }

    const handlePageClick = ({ selected }: { selected: number }) => {
        if (pageData) {
            const newOffset = (selected * itemsPerPage) % propertiesState.rolls.length;
            setItemOffset(newOffset);
        }
    };

    const handlePageData = useCallback((data: PropertyObject[]) => {
        const endOffset = itemOffset + itemsPerPage;
        setPageData(data.slice(itemOffset, endOffset));
        setTotalPages(Math.ceil(data.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);

    const filterRolls = (searchString: string) => {
        if (searchString) {
            const filteredData = propertiesState.rolls.filter(currentProperty => currentProperty.rollNumber.includes(searchString));
            handlePageData(filteredData);
        }
        else {
            handlePageData(propertiesState.rolls);
        }
    }

    useEffect(() => {
        if (propertiesState.found) {
            handlePageData(propertiesState.rolls);
            setShowValidateWacDialog(false)
            dispatch({ type: RESET_GET_PROFILE_OVERVIEW })
            gettingProperties.current = false
        } else {
            if (isValidateWacEnabled) {
                setShowValidateWacDialog(true)
                if (propertiesState.message && gettingProperties.current) {
                    addError({
                        primaryKey: "validatewac",
                        ruleLevel: "error",
                        message: propertiesState.message,
                        messageFr: propertiesState.message,
                    })
                    gettingProperties.current = false
                }
            }
            else {
                if (!gettingProperties.current) {
                    dispatch(getTestRolls())
                    gettingProperties.current = true
                }
            }
        }
    }, [addError, dispatch, handlePageData, isValidateWacEnabled, propertiesState])

    const submitValidateWac = (event: React.FormEvent<HTMLFormElement>) => {
        dispatch({ type: RESET_PROPERTIES })
        clearErrors()

        event.preventDefault()
        const data: FormData = new FormData(event.target as HTMLFormElement);
        const formValues = Object.fromEntries(data.entries()) as Record<string, string>
        dispatch(validateWac(formValues))
        gettingProperties.current = true
    }

    const closeValidateWacDialog = () => {
        window.location.href = `${process.env.REACT_APP_AMP_URL}/#/auth/home/reg-user`;
    }
    useEffect(() => {
        setFormErrors(errors)
    }, [errors])

    return (
        <>
            {
                showValidateWacDialog ?
                    <>
                        <div className="modal-bg">
                            <div className="modal-container">
                                <div className="modal-header">
                                    <span className="modal-title">{t("validate-wac.header")}</span>
                                    <div className="close-btn">
                                        <Button forceEnabled={true} type="cancel" ariaLabel="cancel" onClick={() => closeValidateWacDialog()}>
                                            <FontAwesomeIcon icon={faClose} />
                                        </Button>
                                    </div>
                                </div>
                                <div className="modal-body">
                                    <LoadingSpinner loading={propertiesState.loading}>
                                        <div className='flex flex-column align-items-center justify-content-center'>
                                            <ErrorMessages errors={formErrors} showHeader={false} />
                                            <p></p>
                                            <form onSubmit={(e) => submitValidateWac(e)}>
                                                <div className='flex flex-column align-items-start'>
                                                    {t("validate-wac.roll-number")}
                                                    <Input name={'rollNumber'} id={'rollNumber'} type={'text'} />
                                                    {t("validate-wac.wac")}
                                                    <Input name={'wac'} id={'wac'} type={'text'} />
                                                </div>
                                                <div className='button-group justify-content-center'>
                                                    <Button type='submit' text={t("validate-wac.login")} />
                                                </div>
                                            </form>
                                            <p></p>
                                        </div>
                                    </LoadingSpinner>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <Header showAutoSave={false} isInternalUser={false} showRollNumber={false} />
                        <LandingBanner />
                        <div className='landing-body-container'>
                            <h1 className='landing-heading'>
                                {t("welcome-heading")}
                            </h1>
                            <div className='landing-body'>
                                <div className='search-roll-container'>
                                    <span className='select-text'>{t("select-first")}</span>
                                    <Search showInputByDefault={false} handleSearch={filterRolls} />
                                </div>
                                <LoadingSpinner loading={profileOverview.loading || globalSettingState.loading || propertiesState.loading}>
                                    <div className="profile-list-container">
                                        <table className='profile-table' cellSpacing="0" cellPadding="0">
                                            <thead>
                                                <tr className='profile-table-heading-container'>
                                                    <th className='roll-width'>{t("title.roll-number")}</th>
                                                    <th>{t("title.address")}</th>
                                                    <th className='type-width'>{t("title.profile-type")}</th>
                                                    <th className='text-right status-width'>{t("title.status")}</th>
                                                </tr>
                                            </thead>
                                            <tbody className='profile-table-body-container'>
                                                {pageData && (pageData).map((property, index) => {
                                                    return (
                                                        <tr key={`property-${index}`} onClick={() => chooseProperty(property)}>
                                                            <td>{property.rollNumber}</td>
                                                            <td>{property.profileAddress}</td>
                                                            <td>{property.propertyTypeName}</td>
                                                            <td className='text-right'>
                                                                <span className={`status`}>
                                                                    {
                                                                        [NEW_CREATION, EXTERNAL_IN_PROGRESS, EXTERNAL_SUBMITTED, INTERNAL_IN_PROGRESS].includes(property.statusCode) ?
                                                                            t("status." + property.statusCode)
                                                                            :
                                                                            t("status.N/A")
                                                                    }
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        {totalPages > 1 &&
                                            <Pagination
                                                breakLabel='...'
                                                nextLabel='Next'
                                                previousLabel='Previous'
                                                onPageChange={handlePageClick}
                                                itemsPerPage={itemsPerPage}
                                                totalPages={totalPages}
                                                setItemsPerPage={handleItemsPerPage} />
                                        }
                                    </div>
                                </LoadingSpinner>
                            </div>
                        </div>
                        <Footer />
                    </>
            }
        </>
    );
}
