import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../common/Button/Button';
import "./GEV.css";

export default function ConfirmDialog(props: {
    onClose: () => void,
    onSubmit: () => void,
    title: string,
    body: string,
}): JSX.Element {
    const { t } = useTranslation('common')

    return (
        <div className="modal-bg">
            <div className="modal-container gev-dialog-max-width">
                <div className="modal-header gev-confirm-dialog-header">
                    <span className="gev-modal-title">{props.title}</span>
                </div>
                <div className="modal-body">
                    <div className='flex flex-column align-items-left'>
                        {props.body}
                    </div>
                    <div className='gev-confirm-dialog-button-group'>
                        <Button type='cancel' text={t("no").toUpperCase()} onClick={() => props.onClose()} />
                        <Button type='submit' text={t("yes").toUpperCase()} onClick={() => props.onSubmit()} />
                    </div>
                </div>
            </div>
        </div>
    )
}
