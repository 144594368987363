// Root
export interface BusinessOperationsData {
  commercialGrainElevatorFacility: CommercialGrainElevatorFacilityClass
  feedMillingProcessingFacility: FeedMillingProcessingFacilityClass
  otherAgriBusiness: OtherAgriBusinessClass
  farmingOperationCommercialGrain: FarmingOperationCommercialGrainClass
  noFormSelected: NoFormSelectedClass
}

// Common dataclasses
export interface GrainLicenseType {
  elevatorOperator: boolean | null
  grainDealer: boolean | null
  both: boolean | null
  other: boolean | null
}

export interface PerCrop {
  corn: number | null
  soybeans: number | null
  wheat: number | null
  other: string | null
}

export interface CampaignThroughputData {
  yearValue: number | null
  owner: number | null
  nonOwner: number | null
  totalThroughput: number | null
}

export interface CommercialGrainElevatorFacilityClass {
  selected: boolean
  licenseExpiryDate?: string | null
  grainLicenseType?: GrainLicenseType
  licenseRegisteredTo?: string | null
  isLicenseInUse?: boolean | null
  totalBuschelCapacity?: number | null
  isTruckScaleOnSite?: boolean | null
  truckScaleCapacity?: number | null
  areAnyBuildingsAddedOrRemoved?: boolean | null
  comments?: string | null
}

export interface FeedMillingProcessingFacilityClass {
  selected: boolean
  licenseExpiryDate?: string | null
  grainLicenseType?: GrainLicenseType
  licenseRegisteredTo?: string | null
  isLicenseInUse?: boolean | null
  isTruckScaleOnSite?: boolean | null
  truckScaleCapacity?: number | null
  areAnyBuildingsAddedOrRemoved?: boolean | null
  isCommercialGrainElevatorOperated?: boolean | null
  comments?: string | null
}


export interface OtherAgriBusinessClass {
  selected: boolean
  licenseExpiryDate?: string | null
  grainLicenseType?: GrainLicenseType
  licenseRegisteredTo?: string | null
  isLicenseInUse?: boolean | null
  totalBuschelCapacity?: number | null
  isTruckScaleOnSite?: boolean | null
  truckScaleCapacity?: number | null
  areAnyBuildingsAddedOrRemoved?: boolean | null
  comments?: string | null
  primaryBusinessOperations?: string | null
  isCommercialGrainElevatorOperated?: boolean | null
}

export interface FarmingOperationCommercialGrainClass {
  selected: boolean
  licenseExpiryDate?: string | null
  grainLicenseType?: GrainLicenseType
  licenseRegisteredTo?: string | null
  isLicenseInUse?: boolean | null
  totalBuschelCapacity?: number | null
  totalAcresCropped?: number | null
  acresPerCrop?: PerCrop
  yieldPerCrop?: PerCrop
  isTruckScaleOnSite?: boolean | null
  truckScaleCapacity?: number | null
  percentageOfThroughput?: PercentageOfThroughput
  areAnyBuildingsAddedOrRemoved?: boolean | null
  comments?: string | null
}

// Specific to FarmingOperationCommercialGrain
export interface PercentageOfThroughput {
  campaignYearOne: CampaignThroughputData
  campaignYearTwo: CampaignThroughputData
  campaignYearThree: CampaignThroughputData
}

export interface NoFormSelectedClass {
  selected: boolean
  changesToProperty?: boolean | null
  dateOfChange?: string | null
}


// Functions to get full payloads for operations
export const commercialGrainElevatorFacilitySelectedPayload: CommercialGrainElevatorFacilityClass = {
  selected: true,
  grainLicenseType: {
    elevatorOperator: null,
    grainDealer: null,
    both: null,
    other: null
  },
  licenseRegisteredTo: null,
  isLicenseInUse: null,
  totalBuschelCapacity: null,
  isTruckScaleOnSite: null,
  truckScaleCapacity: null,
  areAnyBuildingsAddedOrRemoved: null,
  comments: null
}

export const feedMillingProcessingFacilitySelectedPayload: FeedMillingProcessingFacilityClass = {
  selected: true,
  grainLicenseType: {
    elevatorOperator: null,
    grainDealer: null,
    both: null,
    other: null
  },
  licenseRegisteredTo: null,
  isCommercialGrainElevatorOperated: null,
  isLicenseInUse: null,
  isTruckScaleOnSite: null,
  truckScaleCapacity: null,
  areAnyBuildingsAddedOrRemoved: null,
  comments: null
}

export const otherAgriBusinessSelectedPayload: OtherAgriBusinessClass = {
  selected: true,
  grainLicenseType: {
    elevatorOperator: null,
    grainDealer: null,
    both: null,
    other: null
  },
  licenseRegisteredTo: null,
  isLicenseInUse: null,
  totalBuschelCapacity: null,
  isTruckScaleOnSite: null,
  truckScaleCapacity: null,
  isCommercialGrainElevatorOperated: null,
  primaryBusinessOperations: null,
  areAnyBuildingsAddedOrRemoved: null,
  comments: null
}

export const farmingOperationCommercialGrainSelectedPayload: FarmingOperationCommercialGrainClass = {
  selected: true,
  grainLicenseType: {
    elevatorOperator: null,
    grainDealer: null,
    both: null,
    other: null
  },
  licenseRegisteredTo: null,
  isLicenseInUse: null,
  totalBuschelCapacity: null,
  totalAcresCropped: null,
  acresPerCrop: {
    corn: null,
    soybeans: null,
    wheat: null,
    other: null
  },
  yieldPerCrop: {
    corn: null,
    soybeans: null,
    wheat: null,
    other: null
  },
  isTruckScaleOnSite: null,
  truckScaleCapacity: null,
  percentageOfThroughput: {
    campaignYearOne: {
      yearValue: Number(localStorage.getItem("activeCampaignYear")) - 3,
      owner: null,
      nonOwner: null,
      totalThroughput: null
    },
    campaignYearTwo: {
      yearValue: Number(localStorage.getItem("activeCampaignYear")) - 2,
      owner: null,
      nonOwner: null,
      totalThroughput: null
    },
    campaignYearThree: {
      yearValue: Number(localStorage.getItem("activeCampaignYear")) - 1,
      owner: null,
      nonOwner: null,
      totalThroughput: null
    },
  },
  areAnyBuildingsAddedOrRemoved: null,
  comments: null
}

export const noFormSelectedPayload: NoFormSelectedClass = {
  selected: true,
  changesToProperty: null,
  dateOfChange: null
}